export default [
  {
    component: 'CNavItem',
		to: '/employee',
		name: 'Employee Management',
    icon: 'cil-people',
  },
	{
    component: 'CNavItem',
    name: 'Org User Management',
    to: '/org/user',
    icon: 'cilUser',
  },
	{
    component: 'CNavItem',
    name: 'About Organization',
    to: '/org',
    icon: 'cil-industry',
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
