export default [
  {
    component: 'CNavItem',
		to: '/representatives',
		name: 'Representatives',
    icon: 'cil-user',
  },
	{
    component: 'CNavItem',
    name: 'Organizaion',
    to: '/organization',
    icon: 'cil-industry',
  },
	{
    component: 'CNavGroup',
    name: 'ChatBot Review',
    to: '/chat',
    icon: 'cilChatBubble',
		items:[{
			component: 'CNavItem',
			name: 'All',
			to: '/chatbot/all',
		},
		{
			component: 'CNavItem',
			name: 'Welcome Flow',
			to: '/chatbot/WQ',
		},
		{
			component: 'CNavItem',
			name: 'Quit Plan Flow',
			to: '/chatbot/QP',
		},
		{
			component: 'CNavItem',
			name: 'Resource Flow',
			to: '/chatbot/RE',
		}]
  },
]
