export default [
  {
    component: 'CNavItem',
		to: '/sessions',
		name: 'Sessions',
    icon: 'cil-folder',
  },
	{
    component: 'CNavItem',
		to: '/leaves',
		name: 'Leaves',
    icon: 'cil-calendar',
  },
]
