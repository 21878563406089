<template>
  <CDropdown >
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
      My Account
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
			<CDropdownItem class="dropdown" @click="Profile"> <CIcon icon="cil-user"/> Profile </CDropdownItem>
      <CDropdownItem class="dropdown" @click="onLogout"> <CIcon icon="cil-lock-locked"/> Logout </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    onLogout () {
      localStorage.clear()
      this.$router.push('/')
    },
		Leaves () {
      this.$router.push('/leaves')
    },
		Profile () {
      // localStorage.clear()
      // this.$router.push('/')
    }
  }
}
</script>
<style>
.dropdown:hover {
	background-color: rgb(50 31 219);
	color:white;
	cursor: pointer;
}
</style>
